body.light-mode {
  background-color: #ffffff;
  color: #000000;
}
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}
.app {
  transition: background-color 0.3s, color 0.3s;
}
