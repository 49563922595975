@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playwrite+AR:wght@100..400&family=Playwrite+CU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+CU:wght@100..400&display=swap");
.skill {
  box-sizing: border-box;
  height: 100vh;
  padding: 5rem 4% 2rem;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
}
.skill-subtitle {
  text-align: center;
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 600;
}
.skill-subtitle.light-mode {
  color: hsl(0, 0%, 40%);
}
.skill-subtitle.dark-mode {
  color: hsl(0, 0%, 100%);
}
.filter-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  justify-content: center;
}
.filter-buttons button {
  color: #9c89b8;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}
.filter-buttons button:hover {
  border: 1px solid white;
}
.filter-buttons button.active {
  box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
  border: 2px solid #9c89b8;
}
.skill-container {
  grid-template-columns: repeat(7, 1fr);
  row-gap: 48px;
  justify-items: center;
  display: grid;
  gap: 30px;
}
.skill-circle {
  width: 100px;
  height: 100px;
}
.CircularProgressbar-path {
  stroke: #9c89b8 !important;
  stroke-linecap: butt !important;
}
.CircularProgressbar-trail {
  stroke: hsl(0, 0%, 93%) !important;
}
.CircularProgressbar-text {
  fill: #9c89b8 !important;
  font-family: "Poppins", sans-serif;
  font-size: 18px !important;
}
.skill-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 24px;
  text-align: center;
}
.skill-title.light-mode {
  color: hsl(0, 0%, 40%);
}
.skill-title.dark-mode {
  color: hsl(0, 0%, 100%);
}
@media screen and (max-width: 768px) {
  .skill {
    height: 100vh;
    padding: 17rem 4% 2rem;
  }
  .skill-title {
    margin-left: -7px;
  }
.filter-buttons button {
  padding: 0.2em;
}
  .skill-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
