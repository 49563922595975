@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+CU:wght@100..400&display=swap");
.education {
  box-sizing: border-box;
  min-height: 100vh;
  /* padding: 7rem 7% 2rem; */
  padding: 5rem 7% 2rem;
  text-align: center;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
}
.education-subtitle {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 50px;
  display: block;
}
.swiper-wrapper {
  margin-bottom: 10px;
}
.education-card {
  background-color: #9c89b8;
  padding: 30px 35px;
  border-radius: 18px;
  overflow: hidden;
}
.education-name {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 50px;
  display: block;
}
.education-card h5 {
  min-height: 35px;
  margin-bottom: 15px;
  font-size: 17px;
}
.swiper-pagination > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: auto;
  display: inline-block;
  position: relative;
}
@media (max-width: 768px) {
  .education {
    height: 100vh;
    padding: 10rem 7% 2rem;
  }
  .swiper-horizontal > .swiper-pagination-bullets {
    margin-left: 15px;
  }
}
