@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playwrite+AR:wght@100..400&family=Playwrite+CU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.contact {
  box-sizing: border-box;
  height: 100vh;
  padding: 4rem 7% 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
}
.contact-form {
  max-width: 600px;
  width: 100%;
  padding: 25px 25px 30px;
  border-radius: 8px;
  margin: 25px;
  border: 2px solid #9c89b8;
}
.contact-form.light-mode {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.contact-form.dark-mode {
  box-shadow: 0 0 10px rgba(208, 203, 203, 0.923);
}
.contact-heading {
  font-size: 30px;
  text-align: center;
}
.contact-form .contact-content {
  margin-top: 20px;
}
.contact-content .contact-input {
  width: 100%;
  height: 50px;
  background: transparent;
  outline: none;
  border-radius: 6px;
  padding: 15px;
  font-size: 17px;
  margin-top: 8px;
}
.contact-content .contact-input.light-mode {
  border: 2px solid #9c89b8;
  color: #333;
  background-color: #fff;
}
.contact-content .contact-input.dark-mode {
  border: 2px solid #9c89b8;
  color: #fff;
  background-color: #333;
}
.contact-content .contact-input.contact-textarea {
  height: 200px;
  resize: none;
}
.contact-btn {
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0.7em 2em;
  border: 3px solid #9c89b8;
  border-radius: 2px;
  position: relative;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
}
.contact-btn.light-mode {
  /* box-shadow: 0 0 3px 6px rgba(0, 0, 0, 0.1); */
  color: #9c89b8;
}
.contact-btn.dark-mode {
  /* box-shadow: 0 3px 6px rgba(163, 158, 158, 0.827); */
  color: #9c89b8;
}
.contact-btn::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: #9c89b8;
  z-index: -1;
}
.contact-btn:hover,
.contact-btn:focus {
  color: white;
}
.contact-btn:hover::before,
.contact-btn:focus::before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}
.contact-btn:active {
  transform: scale(0.9);
}
