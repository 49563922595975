.main-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 350px;
  left: 750px;
}
.loading {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}
.loading div {
  width: 8%;
  height: 24%;
  background: #9c89b8;
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade 1s linear infinite;
}
@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
.loading div:first-child {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}
.loading div:nth-child(2) {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}
.loading div:nth-child(3) {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}
.loading div:nth-child(4) {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}
.loading div:nth-child(5) {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}
.loading div:nth-child(6) {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}
.loading div:nth-child(7) {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}
.loading div:nth-child(8) {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}
.loading div:nth-child(9) {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}
.loading div:nth-child(10) {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}
.loading div:nth-child(11) {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}
.loading div:nth-child(12) {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}
