.home {
  box-sizing: border-box;
  height: 100vh;
  padding: 4rem 4% 2rem;
}
.home-content {
  margin-top: 10%;
  margin-left: 150px;
}
.home-content span {
  font-weight: bold;
  font-size: 30px;
}
.home-content p {
  width: 50%;
  height: 20px;
  text-align: justify;
  font-size: 20px;
}
.home-btn {
  text-decoration: none;
  transform: translate(240%,-150%);
  /* display: flex;
  justify-content: center; */
  /* position: absolute;
  bottom: 230px; */
  font-size: 18px;
  padding: 0.7em 2em;
  border-radius: 2px;
  transition: 0.3s ease all;
}
.home-btn::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: #9c89b8;
  z-index: -1;
}
.home-btn:hover.light-mode,
.home-btn:focus.light-mode {
  color: white;
}
.home-btn:hover.light-mode,
.home-btn:focus.dark-mode {
  color: #000000;
}
.home-btn:hover::before,
.home-btn:focus::before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}
.home-btn:active {
  transform: scale(0.9);
}
.home-btn.light-mode {
  color: #9c89b8;
  border: 2px solid #9c89b8;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
}
.home-btn.light-mode:hover {
  color: #ffffff;
  background-color: #9c89b8;
  box-shadow: 0 2px 10px rgba(23, 22, 22, 0.821),
    0 3px 6px rgba(31, 30, 30, 0.772);
}
.home-btn.dark-mode:hover {
  color: #000000;
  background-color: #9c89b8;
  box-shadow: 0 2px 10px rgba(224, 220, 220, 0.821),
    0 3px 6px rgba(217, 216, 216, 0.772);
}
.home-btn.dark-mode {
  background-color: #9c89b8;
  color: #ffffff;
}
.home-btn.dark-mode:hover {
  background-color: #ffffff;
  color: #9c89b8;
}
.home-img {
  height: 370px;
  width: 370px;
  background-image: url("../Images/Vaishnawi_Pic.png");
  margin-left: 55%;
  margin-top: -15%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
}
.home-img.light-mode {
  border: 2px solid #9c89b8;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.8);
}
.home-img.dark-mode {
  border: 2px solid #ffffff;
  box-shadow: 0 0.1rem 0.5rem rgb(241, 241, 241);
}
.home-img:hover {
  transform: scale(1.1, 1.1);
}
@media screen and (max-width: 800px) {
  .home-content h1 {
    margin-left: -120px;
    margin-top: -30px;
  }
  .home-content span {
    margin-left: -68.9px;
  }
  .home-content p {
    margin-left: -120px;
    width: 140%;
    text-align: justify;
  }
  .home-btn {
    bottom: 330px;
    left: 50px;
  }
  .home-img {
    margin-top: 250px;
    position: absolute;
    right: 95px;
    height: 250px;
    width: 250px;
    background-size: cover;
    background-position: center;
  }
}
