@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+CU:wght@100..400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playwrite+AR:wght@100..400&family=Playwrite+CU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Recursive:wght@300..1000&display=swap');
.footer {
  padding-top: 10px;
  /* padding: 2rem 0; */
  /* margin-top: 15rem; */
}
.footer.light-mode{
  background-color: #3c3a40;
}
.footer.dark-mode{
  background-color: rgb(184, 212, 211);
}
.ft-1 h3 {
  font-weight: 700;
  font-family: "Playwrite CU", cursive;
  letter-spacing: 2px;
  padding: 0.5rem 0;
}
.ft-1 h3 img{
  height: 40px;
}
.ft-1 span {
  color: #9c89b8;
}
.ft-1 p {
  padding: 0rem 5rem 0.8rem 0;
  font-weight: 500;
}
.footer-content h5 {
  color: #9c89b8;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
}
.ft-2 ul {
  list-style: none;
  padding-left: 0;
}
.ft-2 ul li {
  padding: 0.35rem 0;
  font-weight: 500;
}
.ft-2 ul li .footer-link{
  cursor: pointer;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
  text-decoration: none;
  font-size: 1.06rem;
}
.ft-2 ul li .footer-link.light-mode{
  color: #fff;
}
.ft-2 ul li .footer-link.dark-mode{
  color: #000;
}
.ft-3 {
  display: flex;
  flex-direction: column;
}
.ft-3 .footer-anchor {
  padding: 0.1rem 0;
  text-decoration: none;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
}
.ft-3 .footer-anchor.light-mode{
  color: #fff;
}
.ft-3 .footer-anchor.dark-mode{
  color: #000;
}
.last-footer {
  background: #9c89b8;
  text-align: center;
  padding: 1rem 0;
}
.last-footer p {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  font-family: "Recursive", sans-serif;
  font-optical-sizing: auto;
}
.last-footer p .footer-techoweit{
  color: #fff;
}